@media only screen and (max-width: 767px) {
  .btn {
    font-size: 14px;
    padding: 14px 27px;
    box-shadow: 0 5px 27px 0 rgba(163, 48, 53, 0.25);
  }
  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-title {
    margin-bottom: 40px;
  }
  .section-title h2 {
    font-size: 27px;
  }
  .section-title .bar {
    height: 3px;
    margin: 18px auto 20px;
  }
  .main-banner {
    height: 100%;
    overflow: hidden;
    padding-top: 140px;
    padding-bottom: 90px;
  }
  .main-banner .hero-content h1 {
    font-size: 34px;
  }
  .main-banner .hero-content p {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .main-banner .hero-content .video-btn {
    font-size: 14px;
    margin-left: 8px;
  }
  .main-banner .hero-content .video-btn i {
    font-size: 23px;
    margin-left: 3px;
  }
  .main-banner .hero-content form {
    max-width: 100%;
    position: relative;
  }
  .main-banner .hero-content form .btn {
    position: relative;
    width: 100%;
    display: block;
    margin-top: 13px;
    padding: 16px 0;
  }
  .main-banner .hero-content.pl-4 {
    padding-left: 0 !important;
  }
  .main-banner .app-image {
    margin-top: 40px;
  }
  .main-banner .app-image img {
    position: relative;
    left: -10px;
    top: 0;
    width: 185px;
  }
  .main-banner .app-image img:last-child {
    position: absolute;
    top: 50px;
    right: 0;
    left: auto;
  }
  .main-banner .signup-download {
    padding: 20px;
    margin: 35px 0 0 0 !important;
  }
  .main-banner .saas-image {
    margin-bottom: 30px;
  }
  .main-banner .saas-image img {
    position: relative;
    display: none;
  }
  .main-banner .saas-image img:last-child {
    display: block;
  }
  .main-banner.saas-home .shape1, .main-banner.saas-home .shape3 {
    display: none;
  }
  .main-banner.saas-home .shape3 {
    bottom: 10px;
    right: 0;
  }
  .main-banner.saas-home .shape-1 {
    opacity: 0.2;
  }
  .main-banner .product-image {
    margin-top: 40px;
  }
  .main-banner .product-image img {
    width: 165px;
  }
  .main-banner .product-image img:nth-child(2) {
    right: 0;
    bottom: 0;
  }
  .main-banner .product-image .offer-badge {
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    font-size: 15px;
    padding-top: 18px;
  }
  .main-banner.product-home .shape1, .main-banner.product-home .shape4, .main-banner.product-home .shape6 {
    display: none;
  }
  .main-banner.product-home .shape3 {
    bottom: 10px;
    right: 0;
  }
  .main-banner.product-home .shape-1 {
    opacity: 0.2;
  }
  .main-banner.app-home .shape4 {
    display: none;
  }
  .main-banner.app-home .shape1 {
    display: none;
  }
  .main-banner.app-home .shape3 {
    bottom: 10px;
    right: 0;
  }
  .main-banner.marketing-home {
    background: #ffffff url(assets/img/bg-point.png) no-repeat fixed right -700px center/auto;
  }
  .main-banner.marketing-home .shape4, .main-banner.marketing-home .shape2 {
    display: none;
  }
  .main-banner.marketing-home .shape1 {
    display: none;
  }
  .main-banner.marketing-home .shape3 {
    bottom: 10px;
    right: 0;
  }
  .main-banner.marketing-home .marketing-image {
    margin-top: 40px;
  }
  .main-banner.marketing-home .marketing-image img {
    display: none;
    position: relative;
  }
  .main-banner.marketing-home .marketing-image img:last-child {
    display: block;
  }
  .main-banner.marketing-home .bubble-animate, .main-banner.marketing-home .shape6 {
    display: none;
  }
  .main-banner.software-home .shape4, .main-banner.software-home .shape2, .main-banner.software-home .shape1, .main-banner.software-home .shape6 {
    display: none;
  }
  .main-banner.software-home .shape3 {
    bottom: 10px;
    right: 0;
  }
  .main-banner.software-home .hero-content .video-btn {
    display: none;
  }
  .main-banner.book-home .book-image {
    margin-top: 40px;
  }
  .main-banner.book-home .book-image img {
    position: relative;
    bottom: 0;
    right: 0;
  }
  .main-banner.book-home .shape9, .main-banner.book-home .shape2 {
    display: none;
  }
  .main-banner.book-home .hero-content span {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .main-banner.payment-processing-home .shape9, .main-banner.payment-processing-home .shape1, .main-banner.payment-processing-home .shape3, .main-banner.payment-processing-home .shape6 {
    display: none;
  }
  .main-banner .paymant-processing-image {
    margin-top: 40px;
  }
  .main-banner .paymant-processing-image img {
    position: relative;
    display: none;
  }
  .main-banner .paymant-processing-image img:last-child {
    display: block;
  }
  .main-banner .startup-image {
    margin-top: 40px;
  }
  .main-banner .startup-image img {
    position: relative;
    display: none;
  }
  .main-banner .startup-image img:last-child {
    display: block;
  }
  .startup-home .main-banner .shape1, .startup-home .main-banner .shape, .startup-home .main-banner .shape6, .startup-home .main-banner .shape3, .startup-home .main-banner .shape9, .startup-home .main-banner .shape2 {
    display: none;
  }
  .startup-home.owl-theme .owl-nav [class*=owl-] {
    top: auto;
    bottom: 10px;
  }
  .creative-shape {
    bottom: -5px;
  }
  .features-area .shape10, .features-area .shape11, .features-area .shape6, .features-area .shape3 {
    display: none;
  }
  .features-area.marketing-features .features-inner-area {
    margin-top: 40px;
  }
  .features-area.marketing-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 40px;
  }
  .features-area.marketing-features .shape11 {
    bottom: 14px;
  }
  .features-area.product-features .features-holder-content {
    margin-bottom: 40px;
    margin-top: 0;
  }
  .features-area.product-features .features-inner-area {
    margin-top: 40px;
  }
  .features-area.product-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .features-area.book-features .features-inner-area {
    margin-top: 30px;
  }
  .features-area.book-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .features-area.startup-features .features-holder-content {
    margin-bottom: 40px;
  }
  .features-area.startup-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .features-area.startup-features .shape11 {
    bottom: 14px;
  }
  .features-area.payment-processing-features {
    padding-bottom: 20px;
  }
  .features-area.payment-processing-features .features-holder-content {
    margin-bottom: 40px;
    margin-top: 0;
  }
  .features-area.payment-processing-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .features-area.payment-processing-features .shape11 {
    bottom: 14px;
  }
  .single-features {
    margin-bottom: 30px;
  }
  .single-features .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 25px;
  }
  .single-features h3 {
    font-size: 20px;
  }
  .features-inner-area {
    margin-top: 10px;
  }
  .features-inner-area .features-image {
    margin-bottom: 40px;
  }
  .features-inner-area .features-inner-content {
    max-width: 100%;
  }
  .features-inner-area .features-inner-content .features-item {
    padding-left: 75px;
  }
  .features-inner-area .features-inner-content .features-item .icon {
    width: 60px;
    height: 60px;
    line-height: 63px;
    font-size: 23px;
  }
  .features-inner-area .features-inner-content .features-item h3 {
    font-size: 20px;
  }
  .single-features-box {
    padding: 20px;
  }
  .single-features-box .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 25px;
  }
  .single-features-box h3 {
    font-size: 20px;
  }
  .new-features-img {
    margin-bottom: 35px;
  }
  .new-features-content .single-inner-features h3 {
    font-size: 20px;
  }
  .new-features-content .single-inner-features i {
    font-size: 25px;
  }
  .boxes-area {
    margin-top: 0;
    padding-top: 20px;
  }
  .boxes-area .single-box {
    margin-top: 30px;
    padding: 20px;
  }
  .boxes-area .single-box h3 {
    font-size: 20px;
  }
  .overview-section .shape3, .overview-section .shape11, .overview-section .shape6 {
    display: none;
  }
  .overview-section .shape7 {
    opacity: 0.4;
  }
  .overview-box {
    margin-bottom: 40px;
  }
  .overview-box .overview-img {
    order: 1;
    margin-bottom: 30px;
  }
  .overview-box .overview-content {
    order: 2;
  }
  .overview-box .overview-content .icon {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
  }
  .overview-box .overview-content h3 {
    font-size: 21px;
  }
  .overview-box .overview-content ul.features-list li {
    width: 100%;
    margin-right: 0;
  }
  .overview-box .app-fetured-item .app-item {
    width: 145px;
    padding: 25px 10px;
    position: relative;
  }
  .overview-box .app-fetured-item .app-item.item-one {
    top: 0;
  }
  .overview-box .app-fetured-item .app-item.item-two {
    left: 50%;
    top: -100px;
  }
  .overview-box .app-fetured-item .app-item.item-three {
    left: 0;
    bottom: 85px;
  }
  .overview-box .app-fetured-item .app-item.item-four {
    right: auto;
    top: auto;
    left: 50%;
    position: absolute;
    bottom: 95px;
  }
  .overview-box.modern-flexible .overview-img {
    order: unset;
    margin-top: 30px;
  }
  .overview-box.modern-flexible .single-item {
    padding-left: 45px;
  }
  .overview-box.modern-flexible .single-item .icon {
    font-size: 25px;
  }
  .overview-box.modern-flexible .single-item h3 {
    font-size: 20px;
  }
  .overview-box.modern-flexible .single-item.right-icon {
    padding-right: 45px;
  }
  .join-img {
    margin-bottom: 35px;
  }
  .join-content h3 {
    font-size: 20px;
  }
  .partner-area {
    padding: 30px 0;
  }
  .woocommerce-topbar {
    text-align: center;
  }
  .woocommerce-topbar .woocommerce-result-count {
    margin-bottom: 15px;
  }
  .cta-area {
    text-align: center;
    padding: 30px 0;
  }
  .cta-area .text-end {
    text-align: center !important;
    margin-top: 25px;
  }
  .discover-content {
    margin-bottom: 40px;
  }
  .discover-content h3 {
    font-size: 26px;
  }
  .discover-content span {
    font-size: 17px;
    letter-spacing: 0;
  }
  .discover-content ul li {
    float: unset;
    width: 100%;
  }
  .single-holder-box {
    padding: 20px;
    margin-bottom: 30px;
  }
  .single-holder-box.mt-30, .single-holder-box.mt-50 {
    margin-top: 0;
  }
  .single-holder-box h3 {
    font-size: 20px;
  }
  .features-holder-content {
    margin-top: 20px;
  }
  .features-holder-content .section-title .bar {
    margin-left: 0;
    margin-right: 0;
  }
  .contact-info-area {
    padding-bottom: 40px;
  }
  .saas-tools .shape1, .saas-tools .shape2 {
    display: none;
  }
  .tab {
    padding: 20px;
  }
  .tab .tabs {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 25px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0;
    padding-bottom: 15px;
    padding-right: 0;
  }
  .tab .tabs li {
    -ms-flex: 0 0 33.3333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    margin-bottom: 20px;
  }
  .tab .tabs li:first-child {
    padding-left: 15px;
  }
  .tab .tabs li:last-child {
    padding-right: 15px;
  }
  .tab .tabs li a {
    font-size: 15px;
  }
  .tab .tabs li a i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  .tab .tab_content .tabs_item .tabs_item_content {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .tab .tab_content .tabs_item .tabs_item_content h3 {
    font-size: 20px;
  }
  .how-app-work {
    padding-bottom: 40px;
  }
  .how-app-work .app-mobile {
    margin-bottom: 50px;
  }
  .how-app-work .single-app-works {
    margin-bottom: 30px;
  }
  .how-app-work .single-app-works .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 25px;
  }
  .how-app-work .single-app-works h3 {
    font-size: 20px;
  }
  .single-funfact {
    margin-bottom: -1px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .single-funfact h3 {
    font-size: 27px;
  }
  .single-funfact p {
    font-size: 15px;
  }
  .single-funfact-box {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    max-width: 185px;
  }
  .single-funfact-box h3 {
    font-size: 27px;
  }
  .single-funfact-box p {
    font-size: 15px;
  }
  .single-funfact-box .icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 25px;
    left: 13px;
    top: 0px;
  }
  .fun-facts-inner-area {
    margin-top: 40px;
  }
  .fun-facts-inner-area .fun-facts-image {
    margin-bottom: 40px;
  }
  .fun-facts-inner-area .fun-facts-inner-content h3 {
    font-size: 20px;
  }
  .app-funfacts-area {
    padding-bottom: 20px;
  }
  .screenshot-contain .mobile-light {
    display: none;
  }
  .screenshot-contain .owl-item.active.center img {
    border-radius: 0;
  }
  .single-product .product-content {
    padding: 20px;
  }
  .single-product .product-content h3 {
    font-size: 20px;
  }
  .services-area {
    padding-bottom: 20px;
  }
  .single-services {
    padding: 20px;
  }
  .single-services h3 {
    font-size: 20px;
  }
  .analysis-form {
    padding: 25px;
    margin-bottom: 35px;
  }
  .analysis-form h3 {
    font-size: 20px;
  }
  .analysis-text h3 {
    font-size: 27px;
    line-height: 32px;
  }
  .team-area {
    padding-bottom: 20px;
  }
  .single-team-member .team-content h3 {
    font-size: 20px;
  }
  .single-team-member-box .team-content h3 {
    font-size: 20px;
  }
  .single-project .project-content h3 {
    font-size: 20px;
  }
  .next-generation-virtual-reality {
    padding-bottom: 20px;
  }
  .next-generation-virtual-reality .single-item {
    padding-left: 45px;
    margin-bottom: 30px;
  }
  .next-generation-virtual-reality .single-item .icon {
    font-size: 30px;
  }
  .next-generation-virtual-reality .single-item h3 {
    font-size: 20px;
  }
  .saas-work-process {
    padding-bottom: 20px;
  }
  .single-work-process {
    margin-bottom: 30px;
    padding: 20px;
  }
  .single-work-process h3 {
    font-size: 20px;
  }
  .pricing-area {
    padding-bottom: 20px;
  }
  .pricingTable {
    margin-bottom: 30px;
  }
  .pricingTable .price-Value {
    font-size: 40px;
  }
  .pricingTable .month {
    font-size: 17px;
  }
  .pricingTable .pricingHeader .title {
    font-size: 16px;
  }
  .pricing-table {
    margin-bottom: 30px;
  }
  .pricing-table .price-header .title {
    font-size: 20px;
  }
  .pricing-table .price-header .price {
    font-size: 40px;
    padding-left: 40px;
  }
  .pricing-table .price-header .price .month {
    font-size: 17px;
  }
  .pricing-table-box {
    margin-bottom: 30px;
  }
  .pricing-table-box .title {
    font-size: 20px;
  }
  .pricing-table-box .price-value {
    font-size: 40px;
  }
  .pricing-table-box .price-value span {
    font-size: 14px;
  }
  .get-started-form {
    max-width: 100%;
    padding: 20px;
  }
  .single-feedback .client-info h3 {
    font-size: 20px;
  }
  .feedback-slides .owl-item.active.center .single-feedback-box {
    margin-top: 0;
  }
  .single-feedback-box .client-info h3 {
    font-size: 20px;
  }
  .app-download .download-image {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 30px;
  }
  .app-download .download-image img {
    position: relative;
    left: -10px;
    top: 0;
    width: 180px;
  }
  .app-download .download-image img:last-child {
    left: auto;
    position: absolute;
    top: 0;
    right: -10px;
  }
  .app-download .section-title .download-btn a {
    padding: 10px 20px 10px 45px;
    font-size: 12px;
    margin-right: 10;
  }
  .app-download .section-title .download-btn a i {
    left: 15px;
    font-size: 20px;
    margin-top: -11px;
  }
  .app-download .section-title .download-btn a span {
    font-size: 13px;
  }
  .single-blog-post .blog-post-content {
    padding: 20px;
  }
  .single-blog-post .blog-post-content h3 {
    font-size: 20px;
  }
  .subscribe-area .newsletter-form {
    max-width: 100%;
  }
  .subscribe-area .newsletter-form .btn {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 18px 0;
  }
  .subscribe-area .newsletter-form #validator-newsletter {
    position: relative;
    bottom: 0;
    font-size: 16px;
    margin-top: 15px;
  }
  .partner-area.app-partner {
    padding: 50px 0;
    border-bottom: 1px solid #eeeeee;
  }
  .platform-connect h2 {
    font-size: 25px;
    margin-bottom: 25px;
  }
  .platform-connect .box {
    margin-top: 15px;
  }
  .platform-connect .box h3 {
    font-size: 20px;
  }
  .page-title-banner {
    padding-top: 135px;
    padding-bottom: 70px;
  }
  .page-title-banner .shape6 {
    top: 5%;
    left: 0;
  }
  .page-title-banner .shape1, .page-title-banner .shape3, .page-title-banner .shape9, .page-title-banner .shape2 {
    display: none;
  }
  .page-title-banner h2 {
    font-size: 27px;
  }
  .about-content {
    margin-bottom: 40px;
  }
  .about-inner-area {
    margin-top: 10px;
  }
  .about-inner-area .about-text {
    margin-top: 30px;
  }
  .about-inner-area .about-text h3 {
    font-size: 20px;
  }
  .login-form {
    max-width: 100%;
    margin-top: 40px;
    padding: 25px;
  }
  .login-form form .text-end {
    text-align: left !important;
  }
  .login-form form .forgot-password {
    margin-bottom: 2px;
    margin-top: 15px;
  }
  .login-form form .form-check {
    margin-top: 15px;
  }
  .login-form form .form-check .form-check-label {
    top: 0;
  }
  .signup-form {
    max-width: 100%;
    margin-top: 40px;
    padding: 25px;
  }
  .signup-form form .form-check {
    margin-top: 15px;
  }
  .signup-form form .form-check .form-check-label {
    top: 0;
  }
  .coming-soon {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .coming-soon .coming-soon-content h1 {
    font-size: 30px;
    line-height: 45px;
  }
  .coming-soon .coming-soon-content #timer div {
    font-size: 40px;
    width: 120px;
    height: 120px;
    padding-top: 27px;
    line-height: 39px;
    margin: 0 5px 15px;
  }
  .coming-soon .coming-soon-content form button {
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    border-radius: 30px;
    margin-top: 10px;
    padding: 13px 0;
  }
  .notfound {
    max-width: 100%;
    padding: 30px;
    position: relative;
    text-align: center;
    border-radius: 5px;
  }
  .notfound .notfound-404 {
    position: relative;
    left: 0;
    top: 0;
    margin-top: 0;
    text-align: center;
    margin: 0 auto;
    right: 0;
  }
  .notfound .notfound-404::before {
    display: none;
  }
  .faq-accordion {
    padding: 20px;
  }
  .faq-accordion .accordion .accordion-item .accordion-title {
    padding: 16px 20px;
    font-size: 17px;
  }
  .faq-contact {
    margin-top: 30px;
    padding: 40px 20px;
    border-radius: 5px;
    text-align: center;
  }
  .faq-contact h3 {
    font-size: 27px;
  }
  .pagination-area {
    margin-top: 30px;
  }
  .pagination-area ul .page-item .page-link {
    padding: 10px 15px;
    font-size: 15px;
  }
  .shop-details {
    padding: 15px;
  }
  .shop-details .product-entry-summary h3 {
    font-size: 20px;
    margin-top: 25px;
  }
  .shop-details .product-entry-summary h4 {
    font-size: 17px;
    margin: 18px 0;
  }
  .shop-details .product-entry-summary form .form-control {
    width: 100px;
  }
  .shop-details .shop-details-tabs #tabs li {
    display: block;
    text-align: center;
  }
  .shop-details .shop-details-tabs .content h3 {
    font-size: 21px;
  }
  .shop-related-prodcut {
    margin-top: 50px;
  }
  .shop-details-area {
    padding-bottom: 20px;
  }
  .screenshot-contain {
    margin-top: 0;
  }
  .screenshot-slides.owl-theme .owl-controls {
    line-height: 0.01;
  }
  .screenshot-slides.owl-theme .owl-controls .owl-nav {
    margin-top: 30px;
    margin-bottom: -8px;
  }
  .team-slides.owl-theme .owl-controls {
    line-height: 0.01;
  }
  .team-slides.owl-theme .owl-controls .owl-nav {
    margin-top: 0;
    margin-bottom: 22px;
  }
  .feedback-slides.owl-theme .owl-controls {
    line-height: 0.01;
  }
  .feedback-slides.owl-theme .owl-controls .owl-nav {
    margin-top: 0;
    margin-bottom: -8px;
  }
  .blog-slides.owl-theme .owl-controls {
    line-height: 0.01;
  }
  .blog-slides.owl-theme .owl-controls .owl-nav {
    margin-top: 0;
    margin-bottom: -8px;
  }
  .project-slides.owl-theme .owl-controls {
    line-height: 0.01;
  }
  .project-slides.owl-theme .owl-controls .owl-nav {
    margin-top: 0;
    margin-bottom: -8px;
  }
  .product-slides.owl-theme .owl-controls {
    line-height: 0.01;
  }
  .product-slides.owl-theme .owl-controls .owl-nav {
    margin-top: 0;
    margin-bottom: -8px;
  }
  .project-details-desc h3 {
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 0;
  }
  .project-details-desc .project-details-information .single-info-box {
    float: left;
    width: 100%;
    margin-top: 20px;
  }
  .project-details-desc .project-details-information .single-info-box p {
    margin-bottom: 0;
  }
  .project-details-desc .project-details-information .single-info-box h4 {
    font-size: 19px;
  }
  .sidebar-area {
    margin-top: 40px;
  }
  .sidebar-area .widget .widget-title {
    font-size: 20px;
  }
  .blog-details-area .blog-details .article-img .date {
    bottom: 2px;
    width: 65px;
    right: 2px;
    height: 60px;
    font-size: 15px;
    padding-top: 8px;
    line-height: 22px;
  }
  .blog-details-area .blog-details .article-content {
    padding: 20px 15px;
  }
  .blog-details-area .blog-details .article-content ul.category {
    margin-bottom: 20px;
  }
  .blog-details-area .blog-details .article-content ul.category li a {
    padding: 3px 14px 1px;
    margin-bottom: 3px;
    font-size: 13px;
  }
  .blog-details-area .blog-details .article-content h3 {
    font-size: 20px;
    margin-bottom: -10px;
    line-height: 26px;
  }
  .blog-details-area .blog-details .article-content .blockquote {
    padding: 20px 15px;
    border-left: 3px solid #F79220;
  }
  .blog-details-area .blog-details .article-content .blockquote p {
    font-size: 15px;
  }
  .blog-details-area .post-controls-buttons {
    text-align: center;
    padding: 20px 15px;
  }
  .blog-details-area .post-controls-buttons div a {
    padding: 7px 18px;
    font-size: 14px;
  }
  .blog-details-area .post-comments {
    padding: 20px 15px;
  }
  .blog-details-area .post-comments h3 {
    font-size: 20px;
  }
  .blog-details-area .post-comments .single-comment {
    padding-left: 0;
  }
  .blog-details-area .post-comments .single-comment .comment-img {
    position: relative;
  }
  .blog-details-area .post-comments .single-comment .comment-content {
    margin-top: 20px;
  }
  .blog-details-area .post-comments .single-comment.left-m {
    margin-left: 30px;
  }
  .blog-details-area .leave-a-reply {
    padding: 20px 15px;
  }
  .blog-details-area .leave-a-reply h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .blog-details-area .leave-a-reply .form-group {
    margin-bottom: 10px;
  }
  .cart-buttons {
    margin-top: 35px;
  }
  .cart-buttons .coupon-box {
    position: relative;
  }
  .cart-buttons .coupon-box button {
    position: relative;
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 13px 0;
    margin-top: 8px;
  }
  .cart-buttons .btn {
    margin-top: 25px;
  }
  .cart-totals {
    margin-top: 35px;
    padding: 25px;
  }
  .cart-totals h3 {
    font-size: 20px;
  }
  .user-actions {
    padding: 15px 18px 13px;
    margin-bottom: 30px;
  }
  .user-actions i {
    display: block;
    margin-bottom: 5px;
    margin-right: 0;
  }
  .checkout-area .title {
    font-size: 20px;
  }
  .billing-details {
    padding: 18px;
  }
  .order-details {
    padding: 18px;
    margin-top: 30px;
  }
  .contact-info-box {
    padding: 20px;
    margin-bottom: 30px;
  }
  .contact-info-box .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 25px;
  }
  .contact-info-box h3 {
    font-size: 20px;
  }
  #contactForm {
    margin-top: 40px;
  }
  .footer-area .single-footer-widget {
    padding: 0 !important;
    margin-bottom: 30px;
  }
  .footer-area .single-footer-widget h3 {
    font-size: 20px;
    margin-bottom: 18px;
  }
  .footer-area .copyright-area {
    margin-top: 20px;
    text-align: center;
  }
  .footer-area .copyright-area ul {
    text-align: center;
    margin-top: 20px;
  }
  .footer-area .copyright-area ul li:first-child {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section-title h2 {
    font-size: 29px;
  }
  .navbar {
    padding: 0;
  }
  .others-option {
    display: none;
  }
  .crake-nav nav .navbar-nav li a {
    font-size: 15px;
    margin: 0 12px;
  }
  .crake-nav nav .navbar-nav li .dropdown-menu {
    left: auto;
    right: 0;
  }
  .main-banner {
    height: 100%;
    padding-top: 170px;
    padding-bottom: 150px;
  }
  .main-banner .hero-content {
    margin-bottom: 45px;
  }
  .main-banner .hero-content h1 {
    font-size: 50px;
  }
  .main-banner .hero-content.pl-4 {
    padding-left: 0 !important;
  }
  .main-banner .signup-download {
    margin: 0 !important;
  }
  .main-banner.saas-home .col-lg-6:first-child {
    order: 2;
  }
  .main-banner.saas-home .col-lg-6:last-child {
    order: 1;
  }
  .main-banner.saas-home .saas-image {
    text-align: center;
  }
  .main-banner.saas-home .saas-image img {
    display: none;
    position: relative;
  }
  .main-banner.saas-home .saas-image img:last-child {
    display: block;
    margin: 0 auto;
  }
  .main-banner .app-image img {
    position: relative;
    left: 0;
    top: 0;
  }
  .main-banner .app-image img:last-child {
    left: auto;
    top: 0;
    position: absolute;
    right: 0;
  }
  .main-banner .product-image img {
    position: relative;
    left: 45px;
  }
  .main-banner .product-image img:nth-child(2) {
    left: auto;
    right: 20px;
    bottom: 0;
  }
  .main-banner .product-image .offer-badge {
    right: 0;
    left: 0;
    text-align: center;
    margin: 0 auto;
  }
  .main-banner.book-home .book-image {
    text-align: center;
  }
  .main-banner.book-home .book-image img {
    position: relative;
    bottom: 0;
    right: 0;
  }
  .main-banner .paymant-processing-image {
    text-align: center;
  }
  .main-banner .paymant-processing-image img {
    position: relative;
    display: none;
  }
  .main-banner .paymant-processing-image img:last-child {
    display: block;
    margin: 0 auto;
  }
  .main-banner .startup-image {
    text-align: center;
  }
  .main-banner .startup-image img {
    position: relative;
    display: none;
  }
  .main-banner .startup-image img:last-child {
    display: block;
    margin: 0 auto;
  }
  .main-banner.marketing-home .shape3, .main-banner.marketing-home .shape1 {
    display: none;
  }
  .main-banner.marketing-home .marketing-image {
    text-align: center;
  }
  .main-banner.marketing-home .marketing-image img {
    position: relative;
    display: none;
  }
  .main-banner.marketing-home .marketing-image img:last-child {
    display: block;
    margin: 0 auto;
  }
  .startup-home .main-banner .shape6, .startup-home .main-banner .shape2, .startup-home .main-banner .shape3, .startup-home .main-banner .shape9 {
    display: none;
  }
  .shape4 {
    display: none;
  }
  .shape1 {
    top: auto;
    left: 0;
    bottom: 0;
  }
  .boxes-area .single-box {
    margin-top: 30px;
  }
  .features-inner-area {
    margin-top: 25px;
  }
  .features-inner-area .features-image {
    margin-bottom: 55px;
  }
  .features-inner-area .features-inner-content .features-item h3 {
    font-size: 21px;
  }
  .features-holder-content {
    margin-top: 45px;
  }
  .features-area.marketing-features .features-inner-area {
    margin-top: 55px;
  }
  .features-area.marketing-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 45px;
  }
  .features-area.product-features .features-holder-content {
    margin-top: 0;
    margin-bottom: 45px;
  }
  .features-area.product-features .features-inner-area {
    margin-top: 55px;
  }
  .features-area.product-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 45px;
  }
  .features-area.payment-processing-features .features-holder-content {
    margin-top: 0;
    margin-bottom: 45px;
  }
  .features-area.payment-processing-features .features-inner-area {
    margin-top: 55px;
  }
  .features-area.payment-processing-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 45px;
  }
  .features-area.book-features .features-inner-area {
    margin-top: 35px;
  }
  .features-area.book-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 45px;
  }
  .features-area.startup-features .features-inner-area .features-inner-content {
    margin-top: 35px;
  }
  .features-area.startup-features .features-inner-area .features-image {
    margin-bottom: 0;
    margin-top: 45px;
  }
  .features-area .shape10, .features-area .shape6, .features-area .shape11, .features-area .shape3 {
    display: none;
  }
  .single-features h3 {
    font-size: 21px;
  }
  .single-features-box h3 {
    font-size: 21px;
  }
  .single-holder-box h3 {
    font-size: 21px;
  }
  .how-app-work {
    padding-bottom: 50px;
  }
  .single-app-works {
    margin-bottom: 30px;
  }
  .single-app-works h3 {
    font-size: 21px;
  }
  .overview-section .shape6, .overview-section .shape10, .overview-section .shape3, .overview-section .shape11 {
    display: none;
  }
  .overview-box .overview-content .icon {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
  }
  .overview-box .overview-content h3 {
    font-size: 21px;
  }
  .overview-box .overview-content ul.features-list li {
    width: 48%;
    margin-right: 6px;
    font-size: 14px;
  }
  .overview-box .app-fetured-item .app-item {
    width: 175px;
    padding: 35px 10px;
  }
  .overview-box .app-fetured-item .app-item.item-two {
    left: 0;
  }
  .overview-box .app-fetured-item .app-item.item-three {
    left: 20px;
  }
  .overview-box .app-fetured-item .app-item.item-four {
    right: 0;
  }
  .overview-box.modern-flexible .overview-img {
    display: none;
  }
  .overview-box.modern-flexible .single-item h3 {
    font-size: 21px;
  }
  .new-features-content .single-inner-features h3 {
    font-size: 21px;
  }
  .join-content h3 {
    font-size: 21px;
  }
  .discover-img {
    margin-top: 30px;
  }
  .new-features-img {
    margin-bottom: 45px;
  }
  .single-funfact h3 {
    font-size: 29px;
  }
  .fun-facts-inner-area {
    margin-top: 70px;
  }
  .fun-facts-inner-area .container-fluid {
    max-width: 720px;
  }
  .fun-facts-inner-area .fun-facts-inner-content {
    margin-top: 45px;
  }
  .fun-facts-inner-area .fun-facts-inner-content h3 {
    font-size: 29px;
  }
  .single-funfact-box {
    margin-bottom: 30px;
  }
  .app-funfacts-area {
    padding-bottom: 50px;
  }
  .next-generation-virtual-reality {
    padding-bottom: 50px;
  }
  .next-generation-virtual-reality .single-item {
    margin-bottom: 30px;
    padding-left: 40px;
  }
  .next-generation-virtual-reality .single-item h3 {
    font-size: 21px;
  }
  .next-generation-virtual-reality .single-item .icon {
    font-size: 25px;
  }
  .faq-contact h3 {
    font-size: 29px;
  }
  .project-details-desc .project-details-information .single-info-box {
    width: 33.3333%;
    margin-top: 20px;
  }
  .project-details-desc .project-details-information .single-info-box p {
    margin-bottom: 0;
  }
  .services-area {
    padding-bottom: 50px;
  }
  .single-services h3 {
    font-size: 21px;
  }
  .analysis-form {
    margin-bottom: 35px;
  }
  .analysis-form h3 {
    font-size: 21px;
  }
  .analysis-text h3 {
    font-size: 29px;
  }
  .saas-work-process {
    padding-bottom: 50px;
  }
  .single-work-process {
    margin-bottom: 30px;
  }
  .single-work-process h3 {
    font-size: 21px;
  }
  .single-project .project-content h3 {
    font-size: 21px;
  }
  .single-feedback .client-info h3 {
    font-size: 21px;
  }
  .single-feedback-box .client-info h3 {
    font-size: 21px;
  }
  .single-product .product-content h3 {
    font-size: 21px;
  }
  .single-team-member .team-content h3 {
    font-size: 21px;
  }
  .single-team-member-box .team-content h3 {
    font-size: 21px;
  }
  .pricingTable {
    margin-bottom: 30px;
  }
  .pricingTable .price-Value {
    font-size: 43px;
  }
  .pricing-area {
    padding-bottom: 50px;
  }
  .pricing-table {
    margin-bottom: 30px;
  }
  .pricing-table .price-header .title {
    font-size: 21px;
  }
  .pricing-table-box {
    margin-bottom: 30px;
  }
  .pricing-table-box .title {
    font-size: 21px;
  }
  .partner-area.app-partner {
    border-bottom: 1px dashed #edf5ff;
    padding-bottom: 50px;
  }
  .app-download .download-image {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 45px;
  }
  .app-download .download-image img {
    position: relative;
    left: 0;
    top: 0;
    width: 410px;
  }
  .app-download .download-image img:last-child {
    left: auto;
    top: 0;
    position: absolute;
    right: 0;
  }
  .platform-connect h2 {
    margin-bottom: 30px;
    font-size: 29px;
  }
  .platform-connect .box {
    margin-top: 30px;
  }
  .platform-connect .box h3 {
    font-size: 21px;
  }
  .discover-content h3 {
    font-size: 21px;
  }
  .discover-content span {
    font-size: 18px;
    letter-spacing: 1px;
  }
  .saas-tools .shape2 {
    display: none;
  }
  .tab .tabs {
    padding-right: 30px;
    padding-left: 30px;
  }
  .tab .tabs li a {
    font-size: 16px;
  }
  .tab .tab_content .tabs_item .tabs_item_content h3 {
    font-size: 21px;
  }
  .about-content {
    margin-bottom: 30px;
  }
  .about-inner-area {
    margin-top: 20px;
  }
  .about-inner-area .about-text {
    margin-top: 30px;
  }
  .about-inner-area .about-text h3 {
    font-size: 21px;
  }
  .sidebar-area {
    margin-top: 40px;
  }
  .product-entry-summary {
    margin-top: 30px;
  }
  .shop-details-area {
    padding-bottom: 50px;
  }
  .single-blog-post .blog-post-content h3 {
    font-size: 21px;
  }
  .team-area {
    padding-bottom: 50px;
  }
  .login-image {
    margin-bottom: 30px;
    text-align: center;
  }
  .login-form h3 {
    font-size: 21px;
  }
  .signup-image {
    margin-bottom: 30px;
    text-align: center;
  }
  .signup-form h3 {
    font-size: 21px;
  }
  .page-title-banner {
    padding-top: 165px;
    padding-bottom: 100px;
  }
  .cart-totals h3 {
    font-size: 21px;
  }
  .checkout-area .title {
    font-size: 21px;
  }
  .contact-info-area {
    padding-bottom: 50px;
  }
  .contact-info-box {
    margin-bottom: 30px;
  }
  #contactForm {
    margin-top: 45px;
  }
  .contact-info-box h3 {
    font-size: 21px;
  }
  .project-details-desc h3 {
    font-size: 21px;
    margin-top: 20px;
  }
  .project-details-desc .project-details-information .single-info-box h4 {
    font-size: 21px;
  }
  .sidebar-area .widget .widget-title {
    font-size: 21px;
  }
  .shop-details .product-entry-summary h3 {
    font-size: 21px;
  }
  .shop-details .shop-details-tabs .content h3 {
    font-size: 21px;
  }
  .shop-related-prodcut {
    margin-top: 60px;
  }
  .blog-details-area .blog-details .article-content h3 {
    font-size: 21px;
  }
  .blog-details-area .post-comments h3 {
    font-size: 21px;
  }
  .blog-details-area .leave-a-reply h3 {
    font-size: 21px;
  }
  .footer-area .single-footer-widget {
    margin-bottom: 30px;
  }
  .footer-area .single-footer-widget h3 {
    font-size: 21px;
  }
  .footer-area .copyright-area {
    margin-top: 50px;
    text-align: center;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-banner {
    height: 100%;
    padding-top: 195px;
    padding-bottom: 160px;
  }
  .main-banner .hero-content.pl-4 {
    padding-left: 0 !important;
  }
  .main-banner .hero-content h1 {
    font-size: 50px;
  }
  .main-banner .startup-image img:nth-child(5) {
    left: 55px;
  }
  .main-banner .app-image img {
    left: 0;
    top: 15px;
    width: 280px;
  }
  .main-banner .app-image img:last-child {
    left: 200px;
    top: 20px;
  }
  .main-banner.marketing-home .shape1 {
    display: none;
  }
  .main-banner.marketing-home .marketing-image img {
    position: relative;
    display: none;
  }
  .main-banner.marketing-home .marketing-image img:last-child {
    display: block;
  }
  .main-banner.book-home .book-image img {
    bottom: -155px;
    right: 45px;
    width: 350px;
  }
  .main-banner .shape4, .main-banner .shape1 {
    display: none;
  }
  .main-banner .shape6 {
    opacity: 0.3;
  }
  .app-download .download-image {
    bottom: 200px;
  }
  .app-download .download-image img {
    left: -10px;
    top: 0;
    width: 310px;
  }
  .app-download .download-image img:last-child {
    left: 180px;
    top: 20px;
  }
  .boxes-area {
    margin-top: -20px;
  }
  .screenshot-contain {
    margin-top: 0;
  }
  .screenshot-contain .mobile-light {
    display: none;
  }
  .screenshot-slides.owl-theme .owl-nav {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .main-banner .app-image img {
    width: 300px;
    top: 0;
    left: 35px;
  }
  .main-banner .app-image img:last-child {
    left: 235px;
    top: 5px;
  }
  .main-banner .paymant-processing-image {
    right: 0;
    top: 40px;
    width: 585px;
  }
  .app-download .download-image {
    bottom: 180px;
  }
  .app-download .download-image img {
    left: 0;
    top: 0;
    width: 310px;
  }
  .app-download .download-image img:last-child {
    left: 200px;
    top: 0;
  }
}
@media only screen and (min-width: 1910px) {
  .main-banner {
    height: 100vh;
  }
  .main-banner .app-image img {
    left: 0;
    top: -40px;
    width: 82%;
  }
  .main-banner .app-image img:last-child {
    left: 380px;
    top: -20px;
  }
  .main-banner .product-image img {
    width: auto;
  }
  .main-banner .product-image img:nth-child(2) {
    right: -145px;
    bottom: -60px;
  }
  .main-banner .book-image img {
    bottom: -280px !important;
    right: 0 !important;
  }
  .main-banner .shape4 {
    left: 20%;
    top: 23%;
  }
  .app-download .download-image img {
    left: -75px;
    top: 0;
    width: 485px;
  }
  .app-download .download-image img:last-child {
    left: 280px;
    top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .others-option {
    display: none;
  }
  .navbar {
    padding: 0;
  }
  .navbar-light .navbar-toggler {
    color: #ffffff;
    border-color: #ffffff;
  }
  .navbar-light .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-light #navbarSupportedContent {
    margin-top: 15px;
    background: #ffffff;
  }
  .crake-nav nav .navbar-nav {
    padding: 10px 0;
  }
  .crake-nav nav .navbar-nav li {
    padding: 10px 0 !important;
  }
  .crake-nav nav .navbar-nav li a {
    color: #000000 !important;
  }
  .crake-nav nav .navbar-nav li .dropdown-menu {
    box-shadow: unset;
    background: #ffffff;
    position: relative;
    top: 0 !important;
    left: 0;
    width: 100%;
    border: 1px solid #eee;
    opacity: 1;
    border-radius: 0;
    padding: 7px;
    display: none;
    visibility: visible;
  }
  .crake-nav nav .navbar-nav li .dropdown-menu.show {
    display: block;
  }
  .crake-nav.is-sticky {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .crake-nav.marketing-navbar .navbar-light .navbar-toggler {
    color: #000000;
    border-color: #000000;
  }
  .crake-nav.marketing-navbar .navbar-light .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .crake-nav.marketing-navbar .navbar-light #navbarSupportedContent {
    background: #000000;
  }
  .crake-nav.marketing-navbar nav .navbar-nav li a {
    color: #ffffff !important;
  }
  .crake-nav.marketing-navbar nav .navbar-nav li .dropdown-menu {
    background: #171414;
    border: none;
  }
}